define("tabs/headtabs/service", ["exports", "@ember/service", "@glimmer/tracking", "@ember/array", "@ember/object"], function (_exports, _service, _tracking, _array, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let HeadtabsService = (_dec = (0, _object.computed)('v'), (_class = class HeadtabsService extends _service.default {
    get x() {
      return this.v;
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "openTabs", _descriptor, this);

      _initializerDefineProperty(this, "v", _descriptor2, this);

      _initializerDefineProperty(this, "u", _descriptor3, this);

      const t = ['Muhammad Haseeb', 'Nuruddin Ahmed', 'Bilal Ahmad', 'Aziz Ahmad', 'Muhammad Ismail', 'Sobia', 'Fatima', 'Saima'];
      this.v.push(2, 3, 4);
      let newTab = {
        id: 1,
        component: '',
        model: undefined,
        view: undefined,
        title: 'Dashboard',
        isDirty: false,
        isActive: 'activeTab',
        isRoot: true
      };
      this.openTabs.pushObject(newTab);

      for (let i = 0; i < t.length; i++) {
        newTab = {
          id: i + 2,
          component: '',
          model: undefined,
          view: undefined,
          title: t[i],
          isDirty: false,
          isActive: 'inactiveTab',
          isRoot: false
        };
        this.openTabs.pushObject(newTab);
      }
    }

    addDashboard(component, title) {
      let tabs = this.openTabs;
      let newTab = {
        id: 1,
        component,
        model: undefined,
        view: undefined,
        title,
        isDirty: false,
        isActive: 'activeTab',
        isRoot: true
      };
      tabs.pushObject(newTab);
    }

    closeTab(tab, id) {
      if (tab.isActive === 'activeTab') {
        this.openTabs.removeObject(tab);
        return this.openTabs.findBy('id', id);
      }

      return false;
    }

    switchToTab(tab) {
      for (let i = 0; i < this.v.length; i++) {
        this.v[i]++;
      }

      this.u++;
      this.openTabs.forEach(t => {
        t.isActive = 'inactiveTab print_friendly';
      });
      let activeTab = this.openTabs.findBy('id', tab.id);
      activeTab.isActive = 'activeTab';
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "openTabs", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "v", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _array.A)([]);
    }
  }), _applyDecoratedDescriptor(_class.prototype, "x", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "x"), _class.prototype), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "u", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  })), _class));
  _exports.default = HeadtabsService;
});