define("tabs/components/tabs-head/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "wcRsGpE2",
    "block": "[[[10,0],[14,0,\"tab-left-wrapper\"],[12],[1,\"\\n    \"],[10,\"ul\"],[14,0,\"nav-tabs\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"headtabs\",\"openTabs\"]]],null]],null],\"@identity\",[[[1,\"            \"],[11,\"li\"],[16,6,[29,[\"#\",[30,1,[\"title\"]]]]],[16,\"title\",[29,[[30,1,[\"title\"]]]]],[16,0,[29,[\" tab \",[30,1,[\"isActive\"]],\" \"]]],[4,[38,2],[[30,0],\"tabClicked\",[30,1]],null],[12],[1,\"\\n                \"],[10,1],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n\"],[41,[51,[30,1,[\"isRoot\"]]],[[[1,\"                    \"],[11,\"button\"],[24,0,\"close tab-close\"],[24,4,\"button\"],[4,[38,2],[[30,0],\"closeTab\",[30,1]],null],[12],[1,\"×\"],[13],[1,\"\\n\"]],[]],null],[1,\"            \"],[13],[1,\"\\n\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"br\"],[14,5,\"clear:both;\"],[12],[13],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"openTabs\"]]],null]],null],\"@identity\",[[[10,0],[12],[1,[30,2,[\"isActive\"]]],[13],[1,\"\\n\"]],[2]],null],[10,0],[12],[1,[30,0,[\"abc\"]]],[13],[1,\"\\n\"],[11,\"button\"],[4,[38,4],[\"click\",[30,0,[\"incabc\"]]],null],[12],[1,\"+\"],[13],[1,\"\\n\"],[11,\"button\"],[4,[38,4],[\"click\",[30,0,[\"decabc\"]]],null],[12],[1,\"-\"],[13],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"headtabs\",\"x\"]]],null]],null],null,[[[10,0],[12],[1,[30,3]],[1,\"<=\"],[13],[1,\"\\n\"]],[3]],null],[10,0],[12],[1,[30,0,[\"headtabs\",\"u\"]]],[13]],[\"tab\",\"tab\",\"k\"],false,[\"each\",\"-track-array\",\"action\",\"unless\",\"on\"]]",
    "moduleName": "tabs/components/tabs-head/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});