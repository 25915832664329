define("tabs/components/tabs-head/component", ["exports", "@glimmer/component", "@ember/service", "@ember/object", "@glimmer/tracking"], function (_exports, _component, _service, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TabsHeadComponent = (_class = class TabsHeadComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "headtabs", _descriptor, this);

      _initializerDefineProperty(this, "openTabs", _descriptor2, this);

      _initializerDefineProperty(this, "abc", _descriptor3, this);
    }

    closeTab(tab) {
      let tabToGo = null;
      let id;
      const tI = this.openTabs.indexOf(tab);
      const tL = this.openTabs.length - 1;
      let tA = null;

      if (!id) {
        if (tab.isActive === 'activeTab') {
          if (tI >= tL) {
            tA = this.openTabs.toArray()[tI - 1];
          } else {
            tA = this.openTabs.toArray()[tI + 1];
          }

          id = tA.id;
        } else {
          this.openTabs.removeObject(tab);
        }
      } // if (tab.isActive === 'activeTab') {
      //   this.openTabs.removeObject(tab);
      //   tabToGo = this.openTabs.findBy('id', id);
      // } else {
      //   return false;
      // }


      if (tabToGo) {
        this.headtabs.switchToTab(tabToGo); // this.get('route').setParams({
        //     'm': tabToGo.model,
        //     'c': tabToGo.component,
        //     'v': tabToGo.view,
        //     'guid': tabToGo.id,
        //     'props': JSON.stringify(tabToGo.props)
        // });
        // this.get('route').setTitle(tabToGo.title);
      }
    }

    tabClicked(tab) {
      //this.notices.notificationClear();
      //debugger;
      this.headtabs.switchToTab(tab); // this.get('route').setParams(
      //   {
      //     'm': tab.model,
      //     'c': tab.component,
      //     'v': tab.view,
      //     'guid': tab.id,
      //     'props': JSON.stringify(tab.props)
      //   });
      // this.get('route').setTitle(tab.title);
    }

    incabc() {
      this.abc++;
    }

    decabc() {
      this.abc--;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "headtabs", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "openTabs", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.headtabs.openTabs;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "abc", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 3;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "closeTab", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeTab"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "tabClicked", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "tabClicked"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "incabc", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "incabc"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "decabc", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "decabc"), _class.prototype)), _class);
  _exports.default = TabsHeadComponent;
});