define("tabs/application/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "BCeRIilI",
    "block": "[[[1,[28,[35,0],[\"Application\"],null]],[1,\"\\n\"],[10,0],[14,0,\"header\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"nav-top print_friendly\"],[12],[1,\"\\n    \"],[1,[34,1]],[1,\"\\n\"],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"container\"],[12],[1,\"\\n\"],[13]],[],false,[\"page-title\",\"tabs-head\"]]",
    "moduleName": "tabs/application/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});